import React from "react";
import Layout from "../components/layout/Layout";
import { hasAuthParams, useAuth } from "react-oidc-context";
import SelectDemo from "../components/inputs/SelectDemo";
import ReactPlayer from "react-player";

import MyVimeo from "../components/videos/MyVimeo";

const TestPage = () => {
  return (
    <Layout>
      <ReactPlayer url="https://vimeo.com/874000986" controls="true" />
    </Layout>
  );
};

export default TestPage;

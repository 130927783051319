import axios from "axios";
import React, { useState } from "react";

export const PriceContext = React.createContext();

export const PriceProvider = ({ children }) => {
  const [priceApi, setPriceApi] = useState(null);
  const [isLoadingApiPrice, setIsLoadingApiPrice] = useState(false);

  const fetchPriceData = async () => {
    console.log("=== Fetch Price Data ===");
    setIsLoadingApiPrice(true);
    axios
      .post(process.env.REACT_APP_BACKEND_API_URL + "/total/", {
        multi_user: null,
        data_migration: null,
        calendar_service: null,
        tva_code: null,
      })
      .then(function (response) {
        setIsLoadingApiPrice(false);
        setPriceApi(response.data);
      })
      .catch(function (error) {
        console.log(error);
        setIsLoadingApiPrice(false);
        setPriceApi(null);
      });
  };
  return (
    <PriceContext.Provider
      value={{ priceApi, fetchPriceData, isLoadingApiPrice }}
    >
      {children}
    </PriceContext.Provider>
  );
};

import { useState, useEffect } from "react";
import { createClient } from "contentful";

const useContentful = (contentType) => {
  const [content, setContent] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const client = createClient({
      space: process.env.REACT_APP_CONTENTFUL_SPACE_ID,
      accessToken: process.env.REACT_APP_CONTENTFUL_ACCESS_API,
    });

    const fetchContent = async () => {
      try {
        const response = await client.getEntries({
          content_type: contentType,
          order: "fields.date",
        });
        console.log(response);
        setContent(response.items);
        setLoading(false);
      } catch (error) {
        setError(error);
        setLoading(false);
      }
    };

    fetchContent();
  }, [contentType]);

  return { content, loading, error };
};

export default useContentful;

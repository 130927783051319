import React from "react";
import Layout from "../components/layout/Layout";
import AffiliationForm from "../components/forms/affiliation/AffiliationForm";
import { ScrollRestoration } from "react-router-dom";
import SEO from "../components/seo/SEO";

const AffiliationPage = () => {
  return (
    <Layout>
      <SEO />

      <AffiliationForm />
    </Layout>
  );
};

export default AffiliationPage;

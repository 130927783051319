import React from "react";

const ConditionsVentesText = () => {
  return (
    <div className="mentions-page">
      <h1>Conditions générales de vente</h1>
      <p className="italic">
        Les conditions générales ont été mises à jour pour la dernière fois le
        23 mars 2022.
      </p>
      <h2>1. GÉNÉRAL</h2>
      <p>
        1.1. Les présentes conditions générales de vente sont applicables à
        toutes prestations de services et/ou mises à disposition de licences
        et/ou toutes souscriptions qui sont passées avec la Société Coopérative
        ByVets, dont le siège social est sis Rue Joseph Wauters, 67 à 4500 Huy,
        enregistrée avec le numéro d'entreprise 0753.452.052, ci-après dénommée
        ByVets.
      </p>
      <p>
        1.2. Le client peut être une personne physique, une association de
        personnes physiques ou une personne morale. Le client reconnaît :
        <ul className="pl-10">
          <li>
            • qu’il n’est pas un consommateur au sens de l’article I.1,al.1 , 2°
            du Code de droit économique et qu'il agit donc ici dans un cadre{" "}
            <span className=" font-semibold">exclusivement professionnel</span>{" "}
            ;
          </li>
          <li>
            • qu'il est dûment autorisé à exercer la profession de médecin
            vétérinaire ou, s’il s’agit d’une association ou d'une personne
            morale, que l’objet de celle-ci est l’exercice de l’art vétérinaire.
          </li>
        </ul>
      </p>
      <p>
        1.3. Le client déclare avoir obtenu toutes les éventuelles autorisations
        administratives ou professionnelles nécessaires à l'utilisation par le
        client des services fournis dans les pays vers lesquels il dirige ses
        activités.
      </p>
      <p>
        1.4. Si le client perd son droit à exercer l’art vétérinaire en cours de
        contrat, le contrat sera dissout de plein droit et sans mise en demeure
        préalable pour incapacité. Dans ce cas, aucun remboursement ni indemnité
        ne sera dû.
      </p>

      <h2 className="mt-10">2. OFFRE ET COMMANDE</h2>
      <p>
        2.1. Les prix indiqués sur le site internet de ByVets ou aux documents,
        y compris électroniques, émanant de ByVets ne valent qu’à titre
        indicatif et ne lient pas ByVets. Toutes prestations supplémentaires
        feront l’objet d’une facturation séparée.
      </p>
      <p>
        2.2. Le client accepte l'offre de service, le cas échéant, en renvoyant
        signé le formulaire reprenant l'offre de service émis par ByVets ou en
        validant le formulaire électronique reprenant l'offre de service présent
        sur le site internet de ByVets. Dans l'un et l'autre cas, ce formulaire
        reprend les caractéristiques principales du service.
      </p>
      <p>
        2.3. Toute commande entraine l’adhésion sans réserve aux présentes
        conditions générales de vente qui prévalent sur les éventuelles
        conditions générales du client, sauf dérogation écrite et expresse de
        ByVets.
      </p>
      <p>
        2.4. La personne qui passe commande est supposée y être habilitée par le
        client. Elle reste tenue de manière solidaire et indivisible au paiement
        de la commande.
      </p>

      <h2 className="mt-10">3. LIVRAISON</h2>
      <p>
        3.1. Les délais de livraison sont donnés à titre indicatif de sorte
        qu’aucune commande ne peut être annulée en raison de l’expiration du
        délai de livraison donné par ByVets.
      </p>
      <p>
        3.2. En cas d’impossibilité de livraison pour cas de force majeure ou
        fait du prince, aucune indemnisation ne pourra être réclamée par le
        client.
      </p>
      <h2 className="mt-10">4. PAIEMENT ET FACTURATION</h2>
      <p>
        4.1. Les factures de ByVets sont payables à l’échéance fixée sur
        celles-ci et, à défaut, à 30 jours. Toute facture impayée à l’échéance
        entraîne l’exigibilité de toutes autres factures même non encore échues.
        Tout retard de paiement à l’échéance entraîne de surcroit, de plein
        droit et sans mise en demeure préalable, des intérêts au taux mensuel de
        1% et le paiement d’une indemnité forfaitaire de 15% du solde restant dû
        avec un minimum de 50,00€. Si le client ne paie pas dans les délais,
        ByVets peut suspendre l’exécution du contrat jusqu’à ce que le paiement
        intégral ait été effectué, ou considérer le contrat comme résolu pour
        faute dans le chef du client.
      </p>
      <h2 className="mt-10">5. RESPONSABILITÉ</h2>
      <p>
        5.1. La responsabilité de ByVets est limitée à son dol et à sa faute
        grave. Sauf disposition contraire expresse, l’indemnisation
        éventuellement due au client est limitée au montant de la facturation
        annuelle hors tva. Le client garantira ByVets contre toute action
        introduite par des tiers fondé sur le rapport contractuel entre ByVets
        et le client.
      </p>
      <p>
        5.2. ByVets ne peut être tenue responsable du non-respect de ses
        obligations, directement ou indirectement lorsqu’ils résultent de la
        force majeure.
      </p>
      <p>
        5.3. En cas de réclamation, le client doit en avertir ByVets et lui
        permettre de procéder aux constatations utiles dans un délai utile. Sous
        peine d’irrecevabilité, toute réclamation doit être adressée à ByVets
        dans les 8 jours suivant la découverte du fait litigieux et par écrit
        recommandé ou par courriel avec accusé de réception.
      </p>
      <h2 className="mt-10">6. RÉSILIATION UNILATÉRALE</h2>
      <p>
        6.1. ByVets se réserve la possibilité de rompre unilatéralement le
        présent contrat, par une notification au client par lettre recommandée
        et/ou par courrier électronique, en cas de non-respect des conditions
        prévues au présent contrat. Toute résolution entraîne le paiement de
        dommages et intérêts dans le chef du client fixés a minima à 75% de la
        redevance annuelle pour la période en cours au moment de la rupture du
        présent contrat, sauf à ce que ByVets justifie d’un préjudice plus
        important.
      </p>
      <p>
        6.2. Sauf accord exprès de ByVets, la faillite ou toute procédure
        collective engagée contre le client entrainera de plein droit la fin de
        la licence dont le bénéfice ne pourra pas être regardé comme un actif
        susceptible de réalisation.
      </p>

      <h2 className="mt-10">7. MODIFICATIONS</h2>
      <p>
        7.1. ByVets se réserve le droit de modifier à tout moment tout ou partie
        des dispositions des Conditions Générales. ByVets en informe le client
        par la mise à disposition du client de la nouvelle version du texte des
        Conditions Générales. À défaut d'indication contraire dans l'information
        fournie au client, les dispositions de la nouvelle version des
        Conditions Générales s'appliquent à la relation contractuelle entre
        ByVets et le client le premier jour du deuxième mois suivant la date de
        leur communication. Si le client s'oppose à l'application des
        dispositions de la nouvelle version des Conditions Générales, la
        Convention prend automatiquement fin le premier jour du deuxième mois
        suivant la date de leur communication (étant entendu que
        l'avant-dernière version des Conditions Générales continuera de
        s'appliquer à la relation contractuelle entre le client et ByVets
        jusqu'à la date de fin de la Convention).
      </p>

      <h2 className="mt-10">8. COMMUNICATIONS</h2>
      <p>
        8.1. Les parties sont valablement informées ou notifiées par écrit des
        actions reprises dans la Convention (par exemple, la réception d'une
        facture, la réception du préavis de résiliation de la Convention, etc.)
        par email transmis à l'adresse email de la personne de contact
        renseignée à la commande ou une adresse email au travers de laquelle une
        partie (ou un de ses représentants) a interagi dans le cadre de
        l'exécution de la Convention. En cas de modification de l'adresse email
        de la personne de contact renseignée, le client est tenu d'en informer
        ByVets de sorte qu'elle puisse en prendre acte.
      </p>
      <p>
        8.2. Pour autant que de besoin, le client reconnaît que la facture de
        ByVets doit être considérée comme transmise au client lorsqu’elle est
        communiquée par email à la personne de contact du client.
      </p>

      <h2 className="mt-10">
        9. DROIT D’APPLICATION ET COMPÉTENCE DES TRIBUNAUX
      </h2>
      <p>
        9.1. Les présentes conditions générales de vente sont gérées par le
        droit belge.
      </p>
      <p>
        9.2. Tout litige ayant trait à l’interprétation ou à l’exécution de la
        commande et des relations contractuelles entre ByVets et le client est
        de la juridiction exclusive des tribunaux de Huy.
      </p>
    </div>
  );
};

export default ConditionsVentesText;

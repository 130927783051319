import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";

import ContentButton from "../../components/buttons/ContentButton";
import axios from "axios";
import Layout from "../../components/layout/Layout";
import { useParams } from "react-router-dom";
import SEO from "../../components/seo/SEO";

const ConfirmationPage = ({ params }) => {
  const [status, setStatus] = useState("pending");
  const [subId, setSubId] = useState();
  const [price, setPrice] = useState();
  const [customer, setCustomer] = useState("");
  const [isStatusUpdated, setIsStatusUpdated] = useState(false);
  const [isUpdatingStatus, setIsUpdatingStatus] = useState(false);

  const URLparams = useParams();
  const textStatus = (status) => {
    if (status !== "") {
      switch (status) {
        case "paid":
          return { text: "accepté", color: "green", animation: "" };
        case "pending":
          return { text: "en cours ...", color: "trinidad", animation: "" };
        case "failed":
          return { text: "en attente", color: "red", animation: "" };
        case "canceled":
          return { text: "en attente", color: "red", animation: "" };
        case "expired":
          return { text: "en attente", color: "red", animation: "" };
        default:
          return { text: "en cours ...", color: "trinidad", animation: "" };
      }
    }
    return { text: "en cours ...", color: "trinidad", animation: "" };
  };

  const getStatut = async (id) => {
    setIsUpdatingStatus(true);
    try {
      const resp = await axios.post(
        process.env.REACT_APP_BACKEND_API_URL + "/subscriptions/status",
        {
          id,
        },
        {
          method: "POST",
          mode: "cors",
        }
      );
      if (resp.data.response !== "") {
        setIsUpdatingStatus(false);
        setStatus(resp.data.response);
        setPrice(resp.data.price);
        setIsStatusUpdated(true);
        setCustomer(resp.data.customer);
        if (resp.data.company !== "") {
          setCustomer(resp.data.company);
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  const goToPayment = () => {
    axios
      .post(
        process.env.REACT_APP_BACKEND_API_URL + "/subscriptions/payment",
        { id: subId },
        {
          method: "POST",
          mode: "cors",
        }
      )
      .then((response) => {
        window.location.replace(response.data.url);
      })
      .catch((e) => {
        console.log(e);
      });
  };

  useEffect(() => {
    console.log(URLparams.id);
    if (!!URLparams.id) {
      const interval = setInterval(() => {
        getStatut(URLparams.id);
      }, 1000);

      return () => clearInterval(interval);
    }
  }, [subId]);

  return (
    <Layout>
      <SEO />

      <section className="mx-auto sm:w-1/2">
        <h2 className="text-midnight-blue-500 font-semibold text-4xl mb-8">
          Merci pour votre confiance !
        </h2>
        <div className="flex justify-center gap-x-4 sm:w-1/2 sm:justify-between">
          <div className="flex items-center gap-x-2  border rounded-full px-3 py-1 text-sm bg-neutral-100 text-neutral-500 mb-4">
            {(status === "canceled" ||
              status === "expired" ||
              status === "failed") && (
              <div
                className={`h-2 w-2 min-w-[8px] rounded-full bg-orange-400 relative `}
              >
                <div
                  className={`h-2 w-2 z-50 ${
                    status === "paid" ? "" : ""
                  } top-0 left-0 absolute rounded-full bg-orange-400`}
                ></div>
              </div>
            )}
            {(status === "" || status === "pending") && (
              <div>
                <div
                  className={`h-2 w-2 min-w-[8px] rounded-full bg-orange-400 relative `}
                >
                  <div
                    className={`h-2 w-2 z-50 ${
                      status === "paid" ? "" : "animate-ping"
                    } top-0 left-0 absolute rounded-full bg-orange-400`}
                  ></div>
                </div>
              </div>
            )}
            {status === "paid" && (
              <div>
                <div
                  className={`h-2 w-2 min-w-[8px] rounded-full bg-green-400 relative `}
                >
                  <div
                    className={`h-2 w-2 z-50 ${
                      status === "paid" ? "" : ""
                    } top-0 left-0 absolute rounded-full bg-green-400`}
                  ></div>
                </div>
              </div>
            )}

            <div className=" transition-all">
              Paiement {textStatus(status).text}
            </div>
          </div>
        </div>

        {(status === "canceled" ||
          status === "expired" ||
          status === "failed") && (
          <div>
            <p className=" text-blue-gray-500 font-light text-lg mb-2">
              Nous vous invitons à payer la somme de <b>{price} €</b>
            </p>
            <ul className="ml-8 list-disc text-blue-gray-500 mb-4 font-light">
              <li>
                sur le compte : <b>CRELAN : BE58 1030 6877 4179</b>
              </li>
              <li>
                avec la communication : <b>{customer}</b>
              </li>
            </ul>

            <div className="flex items-center gap-x-6 mt-8">
              <p className=" text-blue-gray-500 font-light text-lg ">
                Ou cliquez ici :
              </p>
              <button
                className="text-sm sm:text-base flex items-center justify-center  bg-trinidad-500  text-white font-light rounded-full text-center py-2 px-10 sm:p-2 sm:px-4 cursor-pointer"
                onClick={() => {
                  goToPayment();
                }}
              >
                Payer en ligne
              </button>
            </div>
          </div>
        )}
        {status === "paid" && (
          <div className="">
            <p className=" text-blue-gray-500 font-light text-lg mb-8">
              Votre formulaire d’inscription a été envoyé et sera traité dans
              les plus brefs délais. <br />
              <br /> Un mail vous a été envoyé contenant le récapitulatif de
              votre commande. Si vous ne l'avez pas reçu, vérifiez vos spams et
              si vous ne le voyez toujours pas, contactez-nous.
            </p>
          </div>
        )}
        {!isStatusUpdated && (
          <div className="">
            <p className=" text-blue-gray-500 font-light text-lg mb-8">
              Récupération des informations de paiement en cours...
            </p>
          </div>
        )}
        {status === "paid" && (
          <div className="text-center">
            <ContentButton to="/" type="link">
              Retour à l'accueil
            </ContentButton>
          </div>
        )}
      </section>
    </Layout>
  );
};

ConfirmationPage.propTypes = {
  search: PropTypes.object,
};

export default ConfirmationPage;

import React from "react";
import Layout from "./Layout";
import SEO from "../seo/SEO";
import { useAuth } from "react-oidc-context";
import { setPreviousPath } from "../../utils/previousPath";

const Unauthorized = () => {
  const auth = useAuth();

  return (
    <Layout>
      <SEO />
      <div className="text-center max-w-md mx-auto text-lg  text-midnight-blue-500">
        <div className="mb-8">
          Vous devez être connecté(e) pour accèder à cette page
        </div>
        <button
          className="text-sm sm:text-base flex items-center justify-center content-button bg-trinidad-500 text-white font-light rounded-full text-center py-2 px-10 sm:p-2 cursor-pointer"
          onClick={() => {
            setPreviousPath();
            auth.signinRedirect();
          }}
        >
          Se connecter
        </button>
      </div>
    </Layout>
  );
};

export default Unauthorized;

import React, { useEffect, useState, useCallback } from "react";
import { FiMail } from "react-icons/fi";
import avatarFrancois from "../../images/photos/avatars/avatar_francois.png";
import avatarGael from "../../images/photos/avatars/avatar_gael.png";
import avatarGregoire from "../../images/photos/avatars/avatar_gregoire.png";
import avatarCeline from "../../images/photos/avatars/avatar_celine.png";
import avatarDefault from "../../images/photos/avatars/avatar_default.png";
import avatarLogo from "../../images/photos/avatars/avatar_logo.png";
import bgCircles from "../../images/backgrounds/circlesBg.svg";
import { Fade } from "react-awesome-reveal";

const TeamPresentation = () => {
  const [divWidth, setDivWidth] = useState(0);
  const [randomUser, setRandomUser] = useState(null);
  const avatarWidth = 200;
  const employees = [
    {
      name: "Gaël Fontenelle",
      avatar: avatarGael,
      role: "Développeur Back-end",
      email: "gael.fontenelle@byvets.be",
    },
    {
      name: "Grégoire Cartuyvels",
      avatar: avatarGregoire,
      role: "Développeur Front-end",
      email: "gregoire.cartuyvels@byvets.be",
    },
    {
      name: "Céline Du Bois",
      avatar: avatarCeline,
      role: "Support/Communication client",
      email: "celine.dubois@byvets.be",
    },
    {
      name: "Mohamed Chmouri",
      avatar: avatarDefault,

      role: "Développeur",

      email: "mohamed.chmouri@byvets.be",
    },
  ];
  useEffect(() => {
    // Function to update the width state
    const updateWidth = () => {
      const divElement = document.getElementById("sourceDiv");
      if (divElement) {
        const width = divElement.offsetWidth;
        setDivWidth(width);
      }
    };

    // Initial update
    updateWidth();

    // Event listener to update the width state when the window is resized
    window.addEventListener("resize", updateWidth);

    // Cleanup the event listener on component unmount
    return () => {
      window.removeEventListener("resize", updateWidth);
    };
  }, []);

  const [users, setUsers] = useState(employees.slice());

  useEffect(() => {
    // Fisher-Yates shuffle algorithm
    for (let i = users.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [users[i], users[j]] = [users[j], users[i]];
    }

    setUsers([...users]);
  }, []);

  return (
    <div>
      <div className="flex flex-col items-center justify-center md:hidden">
        <div className="text-center flex flex-col justify-center items-center mb-8">
          <img
            className=" max-w-[200px] rounded-full border-8 border-white shadow-lg text-midnight-blue-500 relative mb-2"
            src={avatarFrancois}
            alt=""
          />
          <div className="flex flex-col justify-center items-center">
            <div className="text-center text-base pt-2 text-midnight-blue-500">
              Francois Van Lerberghe
            </div>
            <div className="text-center text-sm font-semibold pt-2 text-midnight-blue-500">
              Développeur
            </div>
            <div className="text-center text-xl font-semibold pt-2 text-midnight-blue-500">
              <a href={`mailto:francois.vanlerberghe@byvets.be`}>
                <FiMail />
              </a>
            </div>
          </div>
        </div>
        <div className="text-center flex flex-col justify-center items-center mb-4">
          <img
            className=" max-w-[200px] rounded-full border-8 border-white shadow-lg text-midnight-blue-500 relative mb-2"
            src={users[0].avatar}
            alt=""
          />
          <div className="flex flex-col justify-center items-center">
            <div className="text-center text-base pt-2 text-midnight-blue-500">
              {users[0].name}
            </div>
            <div className="text-center text-sm font-semibold pt-2 text-midnight-blue-500">
              {users[0].role}
            </div>
            <div className="text-center text-xl font-semibold pt-2 text-midnight-blue-500">
              <a href={`mailto:${users[0].email}`}>
                <FiMail />
              </a>
            </div>
          </div>
        </div>
        <div className="text-center flex flex-col justify-center items-center mb-4">
          <img
            className=" max-w-[200px] rounded-full border-8 border-white shadow-lg text-midnight-blue-500 relative mb-2"
            src={users[1].avatar}
            alt=""
          />
          <div className="flex flex-col justify-center items-center">
            <div className="text-center text-base pt-2 text-midnight-blue-500">
              {users[1].name}
            </div>
            <div className="text-center text-sm font-semibold pt-2 text-midnight-blue-500">
              {users[1].role}
            </div>
            <div className="text-center text-xl font-semibold pt-2 text-midnight-blue-500">
              <a href={`mailto:${users[1].email}`}>
                <FiMail />
              </a>
            </div>
          </div>
        </div>
        <div className="text-center flex flex-col justify-center items-center mb-4">
          <img
            className=" max-w-[200px] rounded-full border-8 border-white shadow-lg text-midnight-blue-500 relative mb-2"
            src={users[2].avatar}
            alt=""
          />
          <div className="flex flex-col justify-center items-center">
            <div className="text-center text-base pt-2 text-midnight-blue-500">
              {users[2].name}
            </div>
            <div className="text-center text-sm font-semibold pt-2 text-midnight-blue-500">
              {users[2].role}
            </div>
            <div className="text-center text-xl font-semibold pt-2 text-midnight-blue-500">
              <a href={`mailto:${users[2].email}`}>
                <FiMail />
              </a>
            </div>
          </div>
        </div>
        <div className="text-center flex flex-col justify-center items-center mb-4">
          <img
            className=" max-w-[200px] rounded-full border-8 border-white shadow-lg text-midnight-blue-500 relative mb-2"
            src={users[3].avatar}
            alt=""
          />
          <div className="flex flex-col justify-center items-center">
            <div className="text-center text-base pt-2 text-midnight-blue-500">
              {users[3].name}
            </div>
            <div className="text-center text-sm font-semibold pt-2 text-midnight-blue-500">
              {users[3].role}
            </div>
            <div className="text-center text-xl font-semibold pt-2 text-midnight-blue-500">
              <a href={`mailto:${users[3].email}`}>
                <FiMail />
              </a>
            </div>
          </div>
        </div>
      </div>
      <div
        id="sourceDiv"
        className="particles-container hidden md:block "
        style={{
          width: "60%",
          height: `${divWidth}px`,
          margin: "auto",
          position: "relative",
          borderRadius: "50%",
          marginBottom: "200px",
          marginTop: "150px",
          backgroundImage: `url(${bgCircles})`,
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
        }}
      >
        <div
          style={{
            position: "absolute",
            left: `calc(50% - ${avatarWidth / 2})`,
            top: `calc(50% - ${avatarWidth / 2})`,
            width: `${avatarWidth}px`,
            height: `${avatarWidth}px`,
            // transform: `translateX(${divWidth / 2 - avatarWidth / 2}px)`,
            transform: `translateX(${
              divWidth / 2 - avatarWidth / 2
            }px) translateY(${divWidth / 2 - avatarWidth / 2}px)
            rotate(-90deg) rotate(${0 * 72}deg) translateX(${
              divWidth / 2
            }px) rotate(90deg)
          `,
            zIndex: "100",
          }}
        >
          <div className="w-full h-full group">
            <img
              className="w-full h-full rounded-full border-8 border-white shadow-lg text-midnight-blue-500 relative"
              src={avatarFrancois}
              alt=""
            />
            <div className=" bg-white bg-opacity-95 p-2   rounded-full flex flex-col items-center justify-center  transition-all">
              <div className="text-center text-base pt-2 text-midnight-blue-500">
                Francois Van Lerberghe
              </div>
              <div className="text-center text-sm font-semibold pt-2 text-midnight-blue-500">
                Développeur
              </div>
              <div className="text-center text-xl font-semibold pt-2 text-midnight-blue-500">
                <a href={`mailto:francois.vanlerberghe@byvets.be`}>
                  <FiMail />
                </a>
              </div>
            </div>

            {/* <div className="text-center text-sm font-light pt-2 text-midnight-blue-500">
              francois.vanlerberghe@byvets.be
            </div> */}
          </div>
        </div>
        <div
          style={{
            position: "absolute",
            left: `calc(50% - ${avatarWidth / 2})`,
            top: `calc(50% - ${avatarWidth / 2})`,
            width: `${avatarWidth}px`,
            height: `${avatarWidth}px`,
            // transform: `translateX(${divWidth / 2 - avatarWidth / 2}px)`,
            transform: `translateX(${
              divWidth / 2 - avatarWidth / 2
            }px) translateY(${divWidth / 2 - avatarWidth / 2}px)
            rotate(-90deg) rotate(${1 * 72}deg) translateX(${
              divWidth / 2
            }px) rotate(90deg) rotate(-${1 * 72}deg)
          `,
            zIndex: "100",
          }}
        >
          <div className="w-full h-full group">
            <img
              className="w-full h-full rounded-full border-8 border-white shadow-lg text-midnight-blue-500 relative"
              src={users[2].avatar}
              alt=""
            />
            <div className="bg-white bg-opacity-95 p-2   rounded-full flex flex-col items-center justify-center  transition-all">
              <div className="text-center text-base pt-2 text-midnight-blue-500">
                {users[2].name}
              </div>
              <div className="text-center text-sm font-semibold pt-2 text-midnight-blue-500">
                {users[2].role}
              </div>
              <div className="text-center text-xl font-semibold pt-2 text-midnight-blue-500">
                <a href={`mailto:${users[2].email}`}>
                  <FiMail />
                </a>
              </div>
            </div>
          </div>
        </div>
        <div
          style={{
            position: "absolute",
            left: `calc(50% - ${avatarWidth / 2})`,
            top: `calc(50% - ${avatarWidth / 2})`,
            width: `${avatarWidth}px`,
            height: `${avatarWidth}px`,
            // transform: `translateX(${divWidth / 2 - avatarWidth / 2}px)`,
            transform: `translateX(${
              divWidth / 2 - avatarWidth / 2
            }px) translateY(${divWidth / 2 - avatarWidth / 2}px)
            rotate(-90deg) rotate(${2 * 72}deg) translateX(${
              divWidth / 2
            }px) rotate(90deg) rotate(-${2 * 72}deg)
          `,
            zIndex: "100",
          }}
        >
          <div className="w-full h-full group">
            <img
              className="w-full h-full rounded-full border-8 border-white shadow-lg text-midnight-blue-500 relative"
              src={users[0].avatar}
              alt=""
            />
            <div className="bg-white bg-opacity-95 p-2  rounded-full flex flex-col items-center justify-center  transition-all">
              <div className="text-center text-base pt-2 text-midnight-blue-500">
                {users[0].name}
              </div>
              <div className="text-center text-sm font-semibold pt-2 text-midnight-blue-500">
                {users[0].role}
              </div>
              <div className="text-center text-xl font-semibold pt-2 text-midnight-blue-500">
                <a href={`mailto:${users[0].email}`}>
                  <FiMail />
                </a>
              </div>
            </div>
          </div>
        </div>
        <div
          style={{
            position: "absolute",
            left: `calc(50% - ${avatarWidth / 2})`,
            top: `calc(50% - ${avatarWidth / 2})`,
            width: `${avatarWidth}px`,
            height: `${avatarWidth}px`,
            // transform: `translateX(${divWidth / 2 - avatarWidth / 2}px)`,
            transform: `translateX(${
              divWidth / 2 - avatarWidth / 2
            }px) translateY(${divWidth / 2 - avatarWidth / 2}px)
            rotate(-90deg) rotate(${3 * 72}deg) translateX(${
              divWidth / 2
            }px) rotate(90deg) rotate(-${3 * 72}deg)
          `,
            zIndex: "100",
          }}
        >
          <div className="w-full h-full group">
            <img
              className="w-full h-full rounded-full border-8 border-white shadow-lg text-midnight-blue-500 relative"
              src={users[1].avatar}
              alt=""
            />
            <div className="bg-white bg-opacity-95 p-2   rounded-full flex flex-col items-center justify-center  transition-all">
              <div className="text-center text-base pt-2 text-midnight-blue-500">
                {users[1].name}
              </div>
              <div className="text-center text-sm font-semibold pt-2 text-midnight-blue-500">
                {users[1].role}
              </div>
              <div className="text-center text-xl font-semibold pt-2 text-midnight-blue-500">
                <a href={`mailto:${users[1].email}`}>
                  <FiMail />
                </a>
              </div>
            </div>
          </div>
        </div>

        <div
          style={{
            position: "absolute",
            left: `calc(50% - ${avatarWidth / 2})`,
            top: `calc(50% - ${avatarWidth / 2})`,
            width: `${avatarWidth}px`,
            height: `${avatarWidth}px`,
            // transform: `translateX(${divWidth / 2 - avatarWidth / 2}px)`,
            transform: `translateX(${
              divWidth / 2 - avatarWidth / 2
            }px) translateY(${divWidth / 2 - avatarWidth / 2}px)
            rotate(-90deg) rotate(${4 * 72}deg) translateX(${
              divWidth / 2
            }px) rotate(90deg) rotate(-${4 * 72}deg)
          `,
            zIndex: "100",
          }}
        >
          <div className="w-full h-full group">
            <img
              className="w-full h-full rounded-full border-8 border-white shadow-lg text-midnight-blue-500 relative"
              src={users[3].avatar}
              alt=""
            />
            <div className="bg-white bg-opacity-95 p-2   rounded-full flex flex-col items-center justify-center  transition-all">
              <div className="text-center text-base pt-2 text-midnight-blue-500">
                {users[3].name}
              </div>
              <div className="text-center text-sm font-semibold pt-2 text-midnight-blue-500">
                {users[3].role}
              </div>
              <div className="text-center text-xl font-semibold pt-2 text-midnight-blue-500">
                <a href={`mailto:${users[3].email}`}>
                  <FiMail />
                </a>
              </div>
            </div>
          </div>
        </div>
        <div
          style={{
            position: "absolute",
            left: `calc(50% - ${avatarWidth / 2})`,
            top: `calc(50% - ${avatarWidth / 2})`,
            width: `${avatarWidth}px`,
            height: `${avatarWidth}px`,
            // transform: `translateX(${divWidth / 2 - avatarWidth / 2}px)`,
            transform: `translateX(${
              divWidth / 2 - avatarWidth / 2
            }px) translateY(${divWidth / 2 - avatarWidth / 2}px)
           
          `,
            zIndex: "100",
          }}
        >
          <div className="w-full h-full">
            <img
              className="w-full h-full rounded-full  text-midnight-blue-500 relative"
              src={avatarLogo}
              alt=""
            />
          </div>
        </div>
        {/*
        <Fade triggerOnce delay={500} duration={1000}>
          <div className=" absolute rounded-full h-[12%] w-[12%] top-[28%] left-[50%] bg-blueish-500 z-20 opacity-70"></div>
        </Fade>
        <Fade triggerOnce delay={100} duration={600}>
          <div className=" absolute rounded-full h-[8%] w-[8%] top-[22%] left-[30%] bg-orangish-500 z-20 opacity-70"></div>
        </Fade>
        <Fade triggerOnce delay={250} duration={750}>
          <div className=" absolute rounded-full h-[16%] w-[16%] top-[56%] left-[14%] bg-blueish-500 z-20 "></div>
        </Fade>
        <Fade triggerOnce delay={330} duration={1200}>
          <div className=" absolute rounded-full h-[12%] w-[12%] top-[68%] left-[69%] bg-orangish-500 z-20 opacity-70"></div>
        </Fade>
         */}
      </div>
    </div>
  );
};

export default TeamPresentation;

import React, { useEffect, useRef, useState } from "react";
import Layout from "../../components/layout/Layout";
import { Link, useParams } from "react-router-dom";
import { Oval } from "react-loader-spinner";

const LoginCallbackPage = () => {
  const [previousPath, setPreviousPath] = useState("/");
  const buttonRef = useRef(null);
  let { redirect } = useParams();
  console.log("redirect", redirect);
  useEffect(() => {
    setPreviousPath(getPreviousPath);
    console.log("previous", previousPath);

    if (previousPath) {
      const timer = setTimeout(() => {
        if (buttonRef.current) {
          buttonRef.current.click();
        }
      }, 1000);

      return () => clearTimeout(timer);
    }
  }, [previousPath]);

  const getPreviousPath = () => {
    const prevPath = window.sessionStorage.getItem("bVpreviousPathStorageKey");
    return prevPath || process.env.REACT_APP_CLIENT_URL;
  };
  return (
    <Layout>
      <div className="text-center">
        <p className="mb-4 font-sans text-midnight-blue-500">
          Connexion en cours...
        </p>
        <div className="flex justify-center items-center">
          <Oval
            height={80}
            width={80}
            color="#002E6D"
            wrapperStyle={{}}
            wrapperClass=""
            visible={true}
            ariaLabel="oval-loading"
            secondaryColor="#549CFF"
            strokeWidth={2}
            strokeWidthSecondary={2}
          />
        </div>
        <Link ref={buttonRef} to={previousPath}>
          Go
        </Link>
      </div>
    </Layout>
  );
};

export default LoginCallbackPage;

import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";

import {
  createBrowserRouter,
  Navigate,
  RouterProvider,
} from "react-router-dom";
import { PriceProvider } from "./contexts/PriceContext";
import { AuthProvider } from "react-oidc-context";
import HomePage from "./pages";
import PegasePage from "./pages/pegase";
import AffiliationPage from "./pages/souscrire";
import ContactPage from "./pages/contacts";
import SupportPage from "./pages/support";
import ConditionsVentePage from "./pages/conditions-generales-de-vente";
import PrivacyPage from "./pages/politique-vie-privee";
import MentionsLegalesPage from "./pages/mentions-legales";
import LicensePegasePage from "./pages/license-pegase";
import DisclaimerPage from "./pages/disclaimer";
import ContactDemoPage from "./pages/contact/demo";
import FormationsPage from "./pages/support/formations";
import ConfirmationPage from "./pages/souscrire/confirmation";
import LoginCallbackPage from "./pages/login/callback";
import { WebStorageStateStore } from "oidc-client-ts";
import UnsubscribePage from "./pages/emails/unsubscribe";
import TestPage from "./pages/test";

const router = createBrowserRouter([
  {
    path: "/",
    element: <HomePage />,
  },
  {
    path: "pegase",
    element: <PegasePage />,
  },
  {
    path: "souscrire",
    element: <AffiliationPage />,
  },
  {
    path: "souscrire/confirmation/",
    element: <ConfirmationPage />,
  },
  {
    path: "souscrire/confirmation/:id",
    element: <ConfirmationPage />,
  },
  {
    path: "support",
    element: <SupportPage />,
  },
  {
    path: "support/formations",
    element: <FormationsPage />,
  },
  {
    path: "contact/demo",
    element: <ContactDemoPage />,
  },
  {
    path: "contacts",
    element: <ContactPage />,
  },
  {
    path: "conditions-generales-de-vente",
    element: <ConditionsVentePage />,
  },
  {
    path: "politique-vie-privee",
    element: <PrivacyPage />,
  },
  {
    path: "license-pegase",
    element: <LicensePegasePage />,
  },
  {
    path: "mentions-legales",
    element: <MentionsLegalesPage />,
  },
  {
    path: "disclaimer",
    element: <DisclaimerPage />,
  },
  {
    path: "login/callback",
    element: <LoginCallbackPage />,
  },
  {
    path: "mailing/help",
    element: <UnsubscribePage />,
  },
  {
    path: "test",
    element: <TestPage />,
  },
  { path: "*", element: <Navigate to={"/"} /> },
]);

// Configuration OIDC
const oidcConfig = {
  authority:
    process.env.REACT_APP_KC_URL + "realms/" + process.env.REACT_APP_KC_REALM,
  client_id: process.env.REACT_APP_KC_CLIENT_ID,
  // redirect_uri: process.env.REACT_APP_CLIENT_URL,
  redirect_uri: process.env.REACT_APP_CLIENT_URL + "/login/callback",
  automaticSilentRenew: true,
  userStore: new WebStorageStateStore({ store: window.localStorage }),
};

const getPreviousPath = () => {
  const prevPath = window.sessionStorage.getItem("bVpreviousPathStorageKey");
  return prevPath || process.env.REACT_APP_CLIENT_URL;
};
// function to remove URL payload when logged
// (if the payload stay, automatic login won't work)
const onSigninCallback = (user) => {
  window.history.replaceState({}, document.title, window.location.pathname);
};

// Get the current URL
const currentURL = window.location.href;

// Use the URL API to parse the URL and get the pathname
const url = new URL(currentURL);
const pathname = url.pathname;

// Now you can work with the 'pathname' variable, which contains "/support/formations"
console.log(pathname);

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <AuthProvider {...oidcConfig} onSigninCallback={() => onSigninCallback()}>
      <PriceProvider>
        <RouterProvider router={router} />
      </PriceProvider>
    </AuthProvider>
  </React.StrictMode>
);

import React, { useEffect, useState } from "react";
import { Toaster } from "react-hot-toast";
import Header from "./Header";
import { hasAuthParams, useAuth } from "react-oidc-context";
import Footer from "./Footer";
import { ScrollRestoration } from "react-router-dom";
import MaintenancePage from "./Maintenance";
import useKonami from "react-use-konami";
import SupportButtons from "../buttons/SupportButtons";

const Layout = ({ children }) => {
  const auth = useAuth();
  const [isAdminKon, setIsAdminKon] = useState(true);

  useKonami(
    () => {
      setIsAdminKon(!isAdminKon);
    },
    {
      code: ["ArrowUp", "ArrowDown", "Enter"],
    }
  );

  if (!isAdminKon) {
    return <MaintenancePage />;
  }
  // useEffect(
  //   // () => {
  //   //   auth.signinSilent();

  //   //   if (
  //   //     !hasAuthParams() &&
  //   //     !auth.isAuthenticated &&
  //   //     !auth.activeNavigator &&
  //   //     !auth.isLoading
  //   //   ) {
  //   //     auth.signinRedirect();
  //   //   }
  //   // },
  //   // [
  //   //   // auth.isAuthenticated,
  //   //   // auth.activeNavigator,
  //   //   // auth.isLoading,
  //   //   // auth.signinSilent,
  //   // ]
  // );

  return (
    <div className="overflow-x-hidden font-sans">
      <ScrollRestoration />

      <Toaster />
      <Header />
      <div className={" container mx-auto min-h-screen pt-40"}>
        <div className="hidden fixed z-50 left-4 lg:block ">
          <SupportButtons vertical />
        </div>

        <main className="px-4 sm:px-8">{children}</main>
      </div>
      <Footer />
    </div>
  );
};

export default Layout;

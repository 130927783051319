import React, { useState } from "react";
import * as Select from "@radix-ui/react-select";
import classnames from "classnames";
import {
  CheckIcon,
  ChevronDownIcon,
  ChevronUpIcon,
} from "@radix-ui/react-icons";
import { browserName } from "react-device-detect";

const SelectDemo = (props) => {
  const [selectedPrefix, setSelectedPrefix] = useState("+32");
  const countries = [
    {
      value: "Allemagne",
      label: "Allemagne",
      countryCode: "DE",
      phonePrefix: "+49",
    },
    {
      value: "Belgique",
      label: "Belgique",
      countryCode: "BE",
      phonePrefix: "+32",
    },
    { value: "France", label: "France", countryCode: "FR", phonePrefix: "+33" },
    {
      value: "Luxembourg",
      label: "Luxembourg",
      countryCode: "LU",
      phonePrefix: "+352",
    },
    { value: "Suisse", label: "Suisse", countryCode: "CH", phonePrefix: "+41" },
  ];
  return (
    <div className="relative flex">
      <Select.Root
        defaultValue="+32"
        onValueChange={(e) => {
          console.log(e);
          props.prefixSelector(e);
          props.formik.setFieldValue("phonePrefix", e);
        }}
        value={props.selectedPrefix}
      >
        <Select.Trigger
          className="inline-flex items-center justify-center rounded-xl rounded-e-none px-[15px] pr-[3px]  leading-none h-[34px] bg-transparent text-violet11 focus:shadow-[0_0_0_2px] focus:shadow-azure-radiance-700 data-[placeholder]:text-violet9 outline-none absolute"
          aria-label={props.ariaLabel}
          ref={props.prefixRef}
        >
          <Select.Value />
        </Select.Trigger>
        <Select.Portal>
          <Select.Content className="overflow-hidden bg-white rounded-md shadow-[0px_10px_38px_-10px_rgba(22,_23,_24,_0.35),0px_10px_20px_-15px_rgba(22,_23,_24,_0.2)]">
            <Select.ScrollUpButton className="flex items-center justify-center h-[25px] bg-white text-midnight-blue-500 cursor-default">
              <ChevronUpIcon />
            </Select.ScrollUpButton>
            <Select.Viewport className="p-[5px]">
              <Select.Group>
                {props.data.map((country) => {
                  return (
                    <SelectItem
                      key={country.countryCode}
                      value={country.phonePrefix}
                    >
                      <div className="flex items-center gap-x-1 font-normal text-midnight-blue-500">
                        <img
                          src={`https://cdn.ipregistry.co/flags/twemoji/${country.countryCode.toLowerCase()}.svg`}
                          height="20"
                          style={{
                            height: "18px",
                          }}
                          alt={country.label}
                        />
                        <div className="select-separator">
                          <ChevronDownIcon />
                        </div>
                        <div className="font-normal">{country.phonePrefix}</div>
                      </div>
                    </SelectItem>
                  );
                })}
              </Select.Group>
            </Select.Viewport>
            <Select.ScrollDownButton className="flex items-center justify-center h-[25px] bg-white text-midnight-blue-500 cursor-default">
              {/* <ChevronDownIcon /> */}
            </Select.ScrollDownButton>
          </Select.Content>
        </Select.Portal>
      </Select.Root>
      <input
        tabIndex={0}
        id="phone"
        name="phone"
        type="text"
        onChange={(e) => {
          const phoneValue = e.target.value.replace(/[^\d\s]/g, "");
          props.formik.setFieldValue("phone", phoneValue);
        }}
        onBlur={props.formik.handleBlur}
        value={props.formik.values.phone}
        className={`border border-trinidad-500 w-full rounded-xl text-base text-midnight-blue-500 font-normal py-1 pr-4 my-outline`}
        style={{
          paddingLeft: 62 + selectedPrefix.length * 10 + "px",
          paddingTop: browserName === "Safari" ? "3.5px" : "4px",
          paddingBottom: browserName === "Safari" ? "3.5px" : "4px",
        }}
      />
    </div>
  );
};

const SelectItem = React.forwardRef(
  ({ children, className, ...props }, forwardedRef) => {
    return (
      <Select.Item
        className={classnames(
          "text-[13px] leading-none text-violet11 rounded-[3px] flex items-center h-[25px] pr-[35px] pl-[25px] relative select-none data-[disabled]:text-mauve8 data-[disabled]:pointer-events-none data-[highlighted]:outline-none data-[highlighted]:bg-violet9 data-[highlighted]:text-violet1",
          className
        )}
        {...props}
        ref={forwardedRef}
      >
        <Select.ItemText>{children}</Select.ItemText>
        <Select.ItemIndicator className="absolute left-0 w-[25px] inline-flex items-center justify-center">
          <CheckIcon />
        </Select.ItemIndicator>
      </Select.Item>
    );
  }
);

export default SelectDemo;

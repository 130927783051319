import React, { useEffect, useState } from "react";

function FocusTracker() {
  const [focusedElement, setFocusedElement] = useState(null);

  useEffect(() => {
    function handleTabPress(event) {
      if (event.key === "Tab") {
        setFocusedElement(document.activeElement);
      }
    }

    document.addEventListener("keydown", handleTabPress);

    return () => {
      document.removeEventListener("keydown", handleTabPress);
    };
  }, []);

  return (
    <div className="fixed top-5">
      <p>Previously focused element: {focusedElement?.tagName}</p>
      {/* Add any other UI elements here */}
    </div>
  );
}

export default FocusTracker;

import React from "react";

const UnsubscribePage = () => {
  return (
    <div className="max-w-xl mx-auto flex flex-col gap-y-2 text-sm my-8">
      <h2 className="text-xl text-midnight-blue-500 font-bold">
        Mails automatiques
      </h2>
      <h3 className="text-base text-black font-semibold mt-4">
        Pourquoi avez-vous reçu un e-mail de notre clinique vétérinaire ?
      </h3>
      <p>
        <span className="font-medium">Rappel de vaccination :</span> Vous avez
        reçu un e-mail de rappel de vaccination pour assurer la santé continue
        de votre animal de compagnie. Ces rappels sont essentiels pour prévenir
        les maladies.
      </p>
      <p>
        <span className="font-medium">Facturation :</span> Les e-mails de
        facturation sont envoyés pour vous fournir des informations
        transparentes sur les coûts des services vétérinaires que nous avons
        fournis à votre animal.
      </p>
      <h3 className="text-base text-black font-semibold mt-4">
        Je ne souhaite plus recevoir ces communications par mail
      </h3>
      Si vous ne souhaitez plus recevoir de rappels de vaccination ou de
      factures par e-mail, contactez votre vétérinaire.
    </div>
  );
};

export default UnsubscribePage;

import React from "react";

const SchoolHatIcon = () => {
  return (
    <svg id="a" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 57.6 46.28">
      <path d="m56.49,17.23L30.88.58c-1.19-.77-2.98-.77-4.17,0L1.11,17.23c-.7.46-1.11,1.14-1.11,1.87s.4,1.42,1.11,1.87l10.53,6.84v8.45c0,4.23,8.64,6.44,17.17,6.44s17.17-2.21,17.17-6.44v-8.45l5.01-3.25v13.74c-.27.21-.5.5-.69.87l-1.98,3.95c-.38.76-.38,1.55,0,2.17.38.62,1.09.98,1.94.98h3.58c.85,0,1.56-.36,1.94-.98.38-.62.39-1.41,0-2.17l-1.98-3.95c-.18-.37-.42-.65-.69-.87v-15.14l3.38-2.19c.7-.46,1.11-1.14,1.11-1.87s-.4-1.42-1.11-1.87Zm-2.63,26.86s.02.03.02.05c-.01,0-.03,0-.05,0h-3.58s-.04,0-.05,0c0-.01.01-.03.02-.05l1.82-3.63,1.82,3.63Zm-10.04-7.82c0,1.75-5.85,4.29-15.02,4.29s-15.02-2.54-15.02-4.29v-14.3c0-1.75,5.85-4.29,15.02-4.29s15.02,2.54,15.02,4.29v14.3Zm11.51-17.09l-4.29,2.79h-.07v.04l-5.01,3.25v-3.3c0-4.23-8.63-6.44-17.17-6.44s-17.17,2.21-17.17,6.44v3.3l-9.36-6.08s-.07-.05-.1-.07c.02-.02.05-.05.1-.07L27.89,2.38c.47-.3,1.36-.31,1.83,0l25.61,16.65s.07.05.1.07c-.02.02-.05.05-.1.08Z" />
      <path d="m41.59,32.23c-3.16-1.3-7.82-2.05-12.79-2.05s-9.63.75-12.79,2.05c-.37.15-.54.57-.39.93.11.28.38.44.66.44.09,0,.18-.02.27-.05,2.99-1.23,7.45-1.94,12.24-1.94s9.25.71,12.24,1.94c.37.15.78-.02.93-.39.15-.37-.02-.78-.39-.93Z" />
    </svg>
  );
};

export default SchoolHatIcon;

import React from "react";
import logo from "../../images/logos/by_vets_RVB.png";
const MaintenancePage = () => {
  return (
    <div className="text-center">
      <img src={logo} alt="" className="max-w-md mx-auto" />
      <h1 className="text-lg font-medium">
        {" "}
        Le site ByVets est en maintenance actuellement.
      </h1>
      <p className="mb-4">
        Il sera disponible dès que les modifications auront été opérées.
      </p>
      <p>Merci de votre compréhension.</p>
      <p>
        L'équipe ByVets - Support :{" "}
        <a href="tel:+32476252536">+32 (0) 476/25 25 36</a>
      </p>
    </div>
  );
};

export default MaintenancePage;

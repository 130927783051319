import React, { useState } from "react";
import useContentful from "../../hooks/useContentfulFormations";
import { capitalizeFirstLetter } from "../../utils/capitalizeFirstLetter";
import { useFormik } from "formik";
import toast from "react-hot-toast";
import axios from "axios";
import ReactModal from "react-modal";
import { BsCheckCircle } from "react-icons/bs";
import ValidationError from "../forms/ValidationError";
import { useAuth } from "react-oidc-context";

const FormationTable = () => {
  const auth = useAuth();
  const { content, loading, error } = useContentful("formations");
  const [hasSentMessage, setHasSentMessage] = useState(false);
  const [isContactModalOpen, setIsContactModalOpen] = useState(false);

  const todayDate = new Date().setHours(0, 0, 0, 0);

  let regEx = new RegExp(
    "([a-zA-Z0-9]+://)?([a-zA-Z0-9_]+:[a-zA-Z0-9_]+@)?([a-zA-Z0-9.-]+\\.[A-Za-z]{2,4})(:[0-9]+)?(/.*)?"
  );

  const formik = useFormik({
    initialValues: {
      formation: "",
      message: "",
    },
    onSubmit: (values, { resetForm }) => {
      setHasSentMessage(false);

      if (regEx.test(values.message)) {
        toast.error(
          "Les urls ne sont pas autorisées dans le contenu du message",
          { position: "bottom-center", id: "message" }
        );
      } else {
        axios
          .post(process.env.REACT_APP_BACKEND_API_URL + "/mail/formation", {
            firstname: auth.user.profile.given_name,
            lastname: auth.user.profile.family_name,
            email: auth.user.profile.email,
            message: values.message,
            formation: values.formation,
          })
          .then(function (response) {
            toast.success("Message envoyé", {
              position: "bottom-center",
              id: "message",
            });
            setHasSentMessage(true);
          })
          .catch(function (error) {
            setHasSentMessage(false);
          });
      }
    },
  });
  const closeModal = () => {
    setIsContactModalOpen(false);
  };

  const onRadioChange = (e) => {
    formik.setFieldValue("formation", e.target.value);
    setHasSentMessage(false);
    const stringParts = e.target.value.split("@");
    formik.setFieldValue(
      "message",
      `Je souhaite m'inscrire à la formation ${capitalizeFirstLetter(
        stringParts[0]
      )} du ${stringParts[1].toLowerCase()}`
    );
  };

  const handleInputChange = () => {
    if (hasSentMessage) {
      formik.resetForm();
      setHasSentMessage(false);
    }
  };

  if (loading) {
    return <p>Loading...</p>;
  }

  if (error) {
    return <p>Error: {error.message}</p>;
  }
  if (!auth.isAuthenticated) {
    return <p>Vous devez être connecté pour avoir accès aux formations</p>;
  }
  return (
    <form onSubmit={formik.handleSubmit}>
      <div className="flex justify-between max-w-2xl mx-auto mb-8">
        <div className=" border px-4 py-4 w-[300px] rounded">
          <h3 className="text-midnight-blue-300 text-center uppercase font-semibold text-xs tracking-widest">
            Formation 1
          </h3>
          <h4 className="text-center text-lg font-semibold mt-2 mb-2">Bases</h4>
          <p className="border-b pb-4 mb-4 text-center text-sm text-neutral-500 font-light"></p>
          <div className="flex">
            <p className="font-medium mb-2">Prochaines dates</p>
          </div>
          <ul className="pl-2  pb-4">
            {content &&
              content.map((item) => {
                const postDate = new Date(item.fields.date).setHours(
                  0,
                  0,
                  0,
                  0
                );
                if (item.fields.type === "Base" && postDate >= todayDate) {
                  let isSameDay = false;

                  if (postDate === todayDate) {
                    isSameDay = true;
                  }
                  let formattedDate = capitalizeFirstLetter(
                    new Date(item.fields.date).toLocaleDateString("fr-FR", {
                      weekday: "long",
                      day: "numeric",
                      month: "long",
                    })
                  );
                  return (
                    <li className="flex items-center">
                      <span className="text-green-500 mr-1">
                        <input
                          type="radio"
                          name="formation"
                          disabled={item.fields.complet || isSameDay}
                          id={
                            item.fields.type.toLowerCase() + "@" + formattedDate
                          }
                          value={
                            item.fields.type.toLowerCase() + "@" + formattedDate
                          }
                          onChange={(e) => {
                            onRadioChange(e);
                          }}
                        />
                      </span>
                      <label
                        htmlFor={
                          item.fields.type.toLowerCase() + "@" + formattedDate
                        }
                        className={`${
                          item.fields.complet || isSameDay
                            ? "text-gray-400"
                            : ""
                        }`}
                      >
                        {formattedDate} {item.fields.complet && "(complet)"}
                      </label>
                    </li>
                  );
                } else {
                  return "";
                }
              })}
          </ul>
        </div>

        <div className=" border px-4 py-4 w-[300px] rounded">
          <h3 className="text-midnight-blue-300 text-center uppercase font-semibold text-xs tracking-widest">
            Formation 2
          </h3>
          <h4 className="text-center text-lg font-semibold mt-2 mb-2">
            Perfectionnement
          </h4>
          <p className="border-b pb-4 mb-4 text-center text-sm text-neutral-500 font-light"></p>
          <div className="flex">
            <p className="font-medium mb-2">Prochaines dates</p>
          </div>
          <ul className="pl-2  pb-4">
            {content &&
              content.map((item) => {
                if (item.fields.type === "Perfectionnement") {
                  let formattedDate = capitalizeFirstLetter(
                    new Date(item.fields.date).toLocaleDateString("fr-FR", {
                      weekday: "long",
                      day: "numeric",
                      month: "long",
                    })
                  );
                  return (
                    <li className="flex items-center">
                      <span className="text-green-500 mr-1">
                        <input
                          type="radio"
                          name="formation"
                          disabled={item.fields.complet}
                          id={
                            item.fields.type.toLowerCase() + "@" + formattedDate
                          }
                          value={
                            item.fields.type.toLowerCase() + "@" + formattedDate
                          }
                          onChange={(e) => {
                            onRadioChange(e);
                          }}
                        />
                      </span>
                      <label
                        htmlFor={
                          item.fields.type.toLowerCase() + "@" + formattedDate
                        }
                        className={`${
                          item.fields.complet ? "text-gray-400" : ""
                        }`}
                      >
                        {formattedDate} {item.fields.complet && "(complet)"}
                      </label>
                    </li>
                  );
                } else {
                  return "";
                }
              })}
          </ul>
        </div>
      </div>
      <div className="flex justify-center items-center">
        <button
          className="text-base text-white font-normal bg-trinidad-500 py-2 px-12 rounded-full disabled:opacity-50 disabled:cursor-not-allowed transition-all flex justify-center items-center"
          disabled={hasSentMessage || formik.values.formation === ""}
          onClick={(e) => {
            e.preventDefault();
            setIsContactModalOpen(true);
          }}
        >
          {hasSentMessage ? "Demande envoyée" : "Demande d'inscription"}
        </button>
      </div>

      <ReactModal
        isOpen={isContactModalOpen}
        onRequestClose={() => closeModal()}
        ariaHideApp={false}
        shouldCloseOnEsc={true}
        shouldCloseOnOverlayClick={true}
        style={{
          overlay: {
            backgroundColor: "rgba(0, 0, 0, 0.75)",
            zIndex: 100,
          },
          content: {
            borderRadius: "30px",
            height: "auto",
            inset: "10px 20px auto 20px",
            boxShadow: "0px 20px 30px rgba(20, 43, 50, 0.1)",
            zIndex: "150",
            maxWidth: "690px",
            margin: "10vh auto",
          },
        }}
      >
        {hasSentMessage ? (
          <>
            <div className="w-full flex flex-col gap-y-4">
              <span className=" mx-auto text-4xl">
                <BsCheckCircle />
              </span>
              <h3 className="text-xl text-center mb-4 text-midnight-blue-500 font-semibold">
                Votre demande d'inscription a bien été envoyée.
              </h3>
              <button
                className="text-base self-end text-trinidad-500 border border-trinidad-500 font-normal bg-white py-2 px-12 rounded-full disabled:opacity-50 disabled:cursor-not-allowed transition-all"
                onClick={() => closeModal()}
              >
                Fermer
              </button>
            </div>
          </>
        ) : (
          <>
            {formik.values.formation !== "" && (
              <div className="p-4">
                <h3 className="text-xl mb-4 text-midnight-blue-500 font-semibold">
                  Votre demande d'inscription :
                </h3>
                <div className="my-8 text-midnight-blue-500 text-sm"></div>
                <div className="w-full  mb-4">
                  <label
                    htmlFor="message"
                    className="block text-xs text-midnight-blue-500 font-medium pl-2"
                  >
                    Message
                  </label>
                  <textarea
                    id="message"
                    name="message"
                    value={formik.values.message}
                    onChange={(e) => {
                      handleInputChange();
                      formik.handleChange(e);
                    }}
                    placeholder={formik.values.message}
                    onBlur={formik.handleBlur}
                    className="border border-trinidad-500 w-full rounded-xl text-sm text-midnight-blue-500 py-1 px-4 h-32"
                  />
                  {formik.errors.message && formik.touched.message ? (
                    <ValidationError>{formik.errors.message}</ValidationError>
                  ) : null}
                </div>
                <div className="flex gap-x-4 justify-end">
                  <button
                    className="text-base text-trinidad-500 border border-trinidad-500 font-normal bg-white py-2 px-12 rounded-full disabled:opacity-50 disabled:cursor-not-allowed transition-all"
                    onClick={() => closeModal()}
                  >
                    Annuler
                  </button>
                  <button
                    className="text-base text-white font-normal bg-trinidad-500 py-2 px-12 rounded-full disabled:opacity-50 disabled:cursor-not-allowed transition-all"
                    onClick={() => formik.submitForm()}
                  >
                    Envoyer
                  </button>
                </div>
              </div>
            )}
          </>
        )}
      </ReactModal>
    </form>
  );
};

export default FormationTable;

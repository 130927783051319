import React from "react";

const ValidationError = ({ children }) => {
  return (
    <div className="text-red-500 ml-2" tabIndex={-1}>
      {children}
    </div>
  );
};

export default ValidationError;

import { Link } from "react-router-dom";
import React from "react";

const SupportButtonsFooter = (props) => {
  return (
    <div
      className={`flex ${
        props.vertical ? "flex-col flex-wrap" : ""
      } justify-center mb-4 overflow-x-hidden`}
    >
      <div
        className={`support-button-footer_container ${
          props.vertical ? "w-full mb-3" : "w-10"
        } mx-2 sm:mx-0 sm:ml-2 sm:flex sm:items-center`}
      >
        <Link
          to="/souscrire"
          className={`bg-midnight-blue-500 border flex items-center border-white w-10 h-10 rounded-xl  ${
            props.vertical ? "inline-block" : ""
          }  hover:bg-white hover:text-midnight-blue-500 hover:shadow-lg transition support-btn-footer`}
        >
          <svg
            width="24"
            height="25"
            viewBox="0 0 24 25"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className="mx-auto"
          >
            <path
              d="M16.25 1.01025V7.85059"
              stroke="white"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M19.7202 4.43066H12.7793"
              stroke="white"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M5.47005 15.403H5.47054H8.86241V14.903L8.8629 15.403C9.94172 15.4019 10.9834 15.7967 11.7905 16.5126C12.5975 17.2283 13.1138 18.2153 13.2417 19.2863C13.2417 19.2865 13.2417 19.2866 13.2417 19.2868L13.708 23.2711L13.7078 23.2712L13.7098 23.2849C13.7274 23.406 13.6985 23.5292 13.6288 23.6298C13.5596 23.7298 13.4551 23.8001 13.3364 23.8265C11.3081 24.2509 9.24081 24.4604 7.16865 24.4513L7.1643 24.4514C5.09291 24.4604 3.02634 24.2511 0.998817 23.827C0.880188 23.7992 0.776056 23.7283 0.706646 23.628C0.636565 23.5268 0.606975 23.403 0.623712 23.281L0.62381 23.281L0.624966 23.2711L1.09122 19.2868C1.09124 19.2866 1.09126 19.2864 1.09128 19.2862C1.21914 18.2152 1.73547 17.2283 2.54243 16.5126C3.34952 15.7967 4.39123 15.4019 5.47005 15.403Z"
              stroke="white"
            />
            <path
              d="M11.2858 9.05172C11.2858 11.3279 9.44058 13.1731 7.16439 13.1731C4.88819 13.1731 3.04297 11.3279 3.04297 9.05172C3.04297 6.77552 4.88819 4.9303 7.16439 4.9303C9.44058 4.9303 11.2858 6.77552 11.2858 9.05172Z"
              stroke="white"
            />
          </svg>
        </Link>
        <div
          className={`support-button-footer_label transition-all text-xs  text-center text-white ${
            props.vertical ? "inline-block" : ""
          } sm:text-white sm:ml-2 sm:font-bold  sm:text-left`}
        >
          Adhésion
        </div>
      </div>
      <div
        className={`support-button-footer_container ${
          props.vertical ? "w-full mb-3" : "w-10"
        } mx-2 sm:mx-0 sm:ml-2 sm:flex sm:items-center`}
      >
        <Link
          to="/support"
          className={`bg-midnight-blue-500 flex items-center border border-white w-10 h-10 rounded-xl  ${
            props.vertical ? "inline-block" : ""
          }  hover:bg-white hover:text-midnight-blue-500 hover:shadow-lg transition support-btn-footer`}
        >
          <svg
            width="25"
            height="28"
            viewBox="0 0 25 28"
            fill="none"
            className="mx-auto"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M15.206 1.26392C17.0318 1.97881 18.6067 3.21514 19.7347 4.81891C20.8483 6.40013 21.4491 8.28522 21.4556 10.2192C21.4575 11.1317 21.6572 12.0329 22.0407 12.8609L23.7804 16.6349L22.7199 16.9783C22.3574 17.0924 22.0394 17.3167 21.8104 17.62C21.5879 17.9189 21.4676 18.2815 21.4671 18.6541V22.428C21.4669 22.5239 21.4477 22.619 21.4105 22.7074C21.3701 22.795 21.3137 22.874 21.2443 22.941C21.1751 23.0112 21.0914 23.0657 20.999 23.0999C20.908 23.1373 20.8107 23.1563 20.7123 23.1563H15.3873L14.2552 26.9943L2.85034 23.8506L4.73729 17.435L4.23164 16.9293C3.21491 15.9081 2.44101 14.6712 1.9673 13.3102C1.49571 11.9608 1.34069 10.521 1.51436 9.10215C1.69089 7.67973 2.1877 6.31611 2.96735 5.11338C3.75834 3.89067 4.81154 2.85936 6.0506 2.09425"
              stroke="white"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M15.3004 17.8461C15.6053 17.6701 15.9581 17.5951 16.3082 17.6313C16.6576 17.6635 16.9872 17.8077 17.2478 18.0426C17.5051 18.2695 17.6789 18.5761 17.7411 18.9135C17.8034 19.2508 17.7506 19.5992 17.5913 19.903C17.4245 20.2133 17.1589 20.4592 16.8365 20.6014C16.5173 20.7484 16.1599 20.7908 15.8151 20.723C15.4703 20.6553 15.1556 20.4808 14.9157 20.224C14.6825 19.9731 14.5391 19.6519 14.5081 19.3107C13.1986 18.5434 12.1264 17.4293 11.4097 16.0915C10.5826 16.2389 9.72979 16.1061 8.98669 15.7141C8.25422 15.3326 7.67579 14.7101 7.34888 13.9517C7.02828 13.2041 6.98288 12.3673 7.22062 11.5893C7.46769 10.8041 7.97828 10.1282 8.66606 9.67585C8.66972 6.85705 8.86254 4.04164 9.24339 1.24868C9.75862 1.14112 10.2807 1.06913 10.8058 1.03345C11.0642 1.00825 11.3238 0.998141 11.5833 1.00323C12.1058 1.00448 12.6276 1.03988 13.1455 1.10901C12.7264 3.8847 12.512 6.68737 12.5041 9.49453C13.0335 9.77403 13.4858 10.1797 13.8211 10.6757C14.1511 11.165 14.3498 11.7312 14.3981 12.3194C14.4463 12.9076 14.3425 13.4981 14.0966 14.0346C13.8451 14.5757 13.4625 15.0453 12.9834 15.4008C13.5209 16.4003 14.3157 17.238 15.2855 17.8273L15.3004 17.8461Z"
              stroke="white"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        </Link>
        <div
          className={`support-button-footer_label transition-all text-xs  text-center text-white ${
            props.vertical ? "inline-block" : ""
          } sm:text-white sm:ml-2 sm:font-bold  sm:text-left`}
        >
          Support
        </div>
      </div>
      <div
        className={`support-button-footer_container ${
          props.vertical ? "w-full mb-3" : "w-10"
        } mx-2 sm:mx-0 sm:ml-2 sm:flex sm:items-center`}
      >
        <a
          href="http://forum.byvets.be"
          rel="noreferrer"
          className={`bg-midnight-blue-500 border flex items-center border-white w-10 h-10 rounded-xl  ${
            props.vertical ? "inline-block" : ""
          }  hover:bg-white hover:text-midnight-blue-500 hover:shadow-lg transition support-btn-footer`}
        >
          <svg
            width="23"
            height="29"
            viewBox="0 0 23 29"
            fill="none"
            className="mx-auto"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M6.91434 17.2374C6.91434 18.4796 5.90735 19.4866 4.66518 19.4866C3.423 19.4866 2.41602 18.4796 2.41602 17.2374C2.41602 15.9953 3.423 14.9883 4.66518 14.9883C5.90735 14.9883 6.91434 15.9953 6.91434 17.2374Z"
              stroke="white"
            />
            <path
              d="M6.74245 21.4555C6.9259 21.5432 7.09709 21.6532 7.2523 21.7826C6.55318 22.5023 6.10534 23.4357 5.9863 24.4421L5.78227 26.156C5.41761 26.182 5.04256 26.1951 4.66688 26.1951L4.66471 26.1951C3.44778 26.2004 2.23367 26.0776 1.04247 25.8286C1.02145 25.8233 1.00303 25.8105 0.990647 25.7926C0.977803 25.7741 0.972381 25.7514 0.975448 25.729L0.975546 25.729L0.976702 25.7191L1.25389 23.3505C1.32795 22.7638 1.61266 22.2241 2.05504 21.8317C2.49762 21.4392 3.06781 21.2209 3.65935 21.2176H5.70021C6.06095 21.2188 6.41694 21.3 6.74245 21.4555Z"
              stroke="white"
            />
            <path
              d="M20.8382 17.2374C20.8382 18.4796 19.8312 19.4866 18.589 19.4866C17.3468 19.4866 16.3398 18.4796 16.3398 17.2374C16.3398 15.9953 17.3468 14.9883 18.589 14.9883C19.8312 14.9883 20.8382 15.9953 20.8382 17.2374Z"
              stroke="white"
            />
            <path
              d="M22.322 26.342L22.2195 25.8526C21.0264 26.1024 19.8103 26.2256 18.5914 26.2204V26.2203H18.5892C18.2135 26.2203 17.8385 26.2073 17.4738 26.1812L17.2698 24.4673C17.2698 24.4673 17.2698 24.4672 17.2697 24.4671C17.2697 24.4671 17.2697 24.467 17.2697 24.467C17.1502 23.4572 16.6997 22.5211 15.9966 21.8004C16.1522 21.6653 16.3252 21.5506 16.5116 21.4595C16.8366 21.3006 17.1935 21.2179 17.5553 21.2176L19.5981 21.2176L19.5986 21.2176C20.1907 21.217 20.7625 21.4337 21.2055 21.8267C21.6483 22.2194 21.9317 22.761 22.002 23.3487C22.002 23.3489 22.002 23.3492 22.0021 23.3494L22.2793 25.7438L22.2801 25.751L22.2812 25.7582C22.2843 25.7795 22.2792 25.8012 22.2669 25.8189C22.2547 25.8366 22.2362 25.849 22.2151 25.8536L22.322 26.342ZM22.322 26.342C22.4666 26.3104 22.5938 26.2251 22.678 26.1035C22.7623 25.9818 22.7973 25.8327 22.776 25.6863L17.0255 26.6447C17.5299 26.6951 18.0595 26.7203 18.5892 26.7203C19.8433 26.7258 21.0945 26.599 22.322 26.342Z"
              stroke="white"
            />
            <path
              d="M15.3606 27.7043L15.2582 27.2149C14.0651 27.4647 12.849 27.5879 11.63 27.5826L11.6256 27.5826C10.4087 27.5879 9.19461 27.4651 8.00341 27.2162C7.98238 27.2108 7.96397 27.198 7.95158 27.1801C7.93874 27.1616 7.93332 27.1389 7.93639 27.1165L7.93648 27.1165L7.93764 27.1067L8.21495 24.7369C8.21497 24.7367 8.21499 24.7366 8.21501 24.7364C8.28523 24.1486 8.56863 23.607 9.01151 23.2142C9.45452 22.8212 10.0263 22.6045 10.6185 22.6051H10.619H12.6367V22.1053L12.6372 22.6051C13.2293 22.6045 13.8011 22.8212 14.2441 23.2142C14.687 23.607 14.9704 24.1486 15.0406 24.7364C15.0407 24.7366 15.0407 24.7368 15.0407 24.7369L15.318 27.1067L15.3178 27.1067L15.3198 27.1204C15.3229 27.1418 15.3178 27.1635 15.3056 27.1812C15.2933 27.1989 15.2748 27.2113 15.2538 27.2159L15.3606 27.7043ZM15.3606 27.7043C15.5052 27.6727 15.6324 27.5874 15.7167 27.4658C15.8009 27.3441 15.8359 27.195 15.8146 27.0485H7.44103C7.42095 27.1948 7.45644 27.3433 7.54049 27.4647C7.62453 27.5861 7.75103 27.6716 7.89502 27.7043C9.12253 27.9613 10.3737 28.0881 11.6278 28.0826C12.8819 28.0881 14.1331 27.9613 15.3606 27.7043ZM12.6367 22.1051H10.619C9.90436 22.1044 9.21434 22.3659 8.67973 22.8401C8.14512 23.3143 7.80308 23.9681 7.71847 24.6777L12.6367 22.1051Z"
              stroke="white"
            />
            <path
              d="M13.8772 18.6245C13.8772 19.8667 12.8702 20.8737 11.6281 20.8737C10.3859 20.8737 9.37891 19.8667 9.37891 18.6245C9.37891 17.3823 10.3859 16.3754 11.6281 16.3754C12.8702 16.3754 13.8772 17.3823 13.8772 18.6245Z"
              stroke="white"
            />
            <path
              d="M9.5826 10.7264L9.44381 10.4522H9.13649H5.52316C5.1859 10.4477 4.86403 10.3101 4.62772 10.0694C4.3908 9.828 4.25921 9.50256 4.26175 9.16435H4.26177V9.16059V2.69303C4.26471 2.35968 4.39843 2.04079 4.63418 1.80503C4.86994 1.56927 5.18884 1.43555 5.52219 1.43262H17.7606C18.0939 1.43555 18.4128 1.56927 18.6486 1.80503C18.8843 2.04078 19.018 2.35966 19.021 2.69299V9.19179C19.018 9.52512 18.8843 9.844 18.6486 10.0797C18.4128 10.3155 18.094 10.4492 17.7606 10.4522H14.1463H13.8389L13.7002 10.7264L12.3414 13.4107C12.3412 13.4112 12.341 13.4116 12.3407 13.4121C12.2745 13.5401 12.1745 13.6475 12.0514 13.7226C11.9279 13.7979 11.786 13.8378 11.6414 13.8378C11.4967 13.8378 11.3549 13.7979 11.2314 13.7226C11.1083 13.6475 11.0082 13.5401 10.942 13.4121C10.9418 13.4116 10.9416 13.4112 10.9413 13.4107L9.5826 10.7264Z"
              stroke="white"
            />
          </svg>
        </a>
        <div
          className={`support-button-footer_label transition-all text-xs  text-center text-white ${
            props.vertical ? "inline-block" : ""
          } sm:text-white sm:ml-2 sm:font-bold  sm:text-left`}
        >
          Forum
        </div>
      </div>
    </div>
  );
};

export default SupportButtonsFooter;

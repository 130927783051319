import React from "react";
import { Helmet } from "react-helmet";

const SEO = () => {
  return (
    <Helmet>
      <meta charSet="utf-8" />
      <title>
        ByVets - Logiciel Pegase, gestion informatique de cabinet vétérinaire
      </title>
      <meta
        name="description"
        content="Quand les vétérinaires prennent les rênes de leur outil informatique..."
      />
    </Helmet>
  );
};

export default SEO;

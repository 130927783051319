import React from "react";

const RgpdPage = () => {
  return (
    <div className="mentions-page">
      <h1>Politique vie privée</h1>
      <h2>
        LA POLITIQUE DE BYVETS EN MATIERE DE COLLECTE ET DE TRAITEMENT DE VOS
        DONNÉES A CARACTÈRE PERSONNEL
      </h2>
      <p>
        La protection de la vie privée des utilisateurs du logiciel Pégase
        (propriété de la société coopérative ByVets), de ses interlocuteurs, et
        de toutes les personnes qui fournissent leurs données à caractère
        personnel à ByVets est d’une importance capitale.
      </p>
      <p>
        ByVets cherche à respecter toute la législation applicable à la collecte
        et au traitement des données à caractère personnel, y compris le
        Règlement général sur la protection des données (RGPD).
      </p>
      <p>
        Cette politique s’applique donc à toutes les personnes qui fournissent à
        ByVets leurs données à caractère personnel en tant que clients,
        fournisseurs, contacts et visiteurs de son site Web. Cette politique
        s’applique également à toutes les méthodes de collecte de données, que
        ces données soient recueillies par téléphone, courriel, formulaire de
        contact sur le site Web, etc.
      </p>
      <h3>Entité responsable du traitement des données :</h3>
      <p>
        ByVets sc
        <br />
        siège social: rue Joseph Wauters, 67 à 4500 HUY.
        <br />
        enregistrée à la BCE sous le n° 0753 452 052
        <br />
      </p>
      <h3>Représentant de contact en charge de la protection des données :</h3>
      <p>
        Stéphane Dahout
        <br />
        stephane.dahout@byvets.be
      </p>
      <h3>Données recueillies.</h3>
      <p>
        ByVets recueille et traite vos données à caractère personnel telles que
        les nom, prénom, titre, adresse, numéro de téléphone, courriel, login,
        mot de passe, référence de l’entreprise pour laquelle vous travaillez,
        numéro du dépôt de médicaments, numéro de l'ordre des médecins
        vétérinaires, afin de pouvoir fournir les services convenus et/ou vous
        informer des services fournis par ByVets. <br />
        ByVets cherche à limiter les données recueillies à celles qui sont
        directement nécessaires à ses fins.
      </p>
      <h3>Base juridique pour la collecte des données.</h3>
      <p>
        ByVets recueille vos données en vue de l’exécution d’un contrat si vous
        avez commandé un service, notamment la location du logiciel Pégase.
        ByVets prévoit également d’utiliser vos données en fonction de ses
        intérêts pour vous tenir au courant des événements spéciaux.
      </p>
      <h3>Objectifs de la collecte de données.</h3>
      <p>
        ByVets utilise vos données pour
        <ul>
          <li>
            fournir les services demandés par ses clients dans le cadre de
            l’exécution des obligations contractuel
          </li>
          <li>
            répondre à vos questions et vous fournir les services demandés.
          </li>
        </ul>
      </p>
      <h3>Collecte directe et indirecte des données.</h3>
      <p>
        En principe, ByVets collecte les données traitées directement auprès de
        vous. Toutefois, ByVets peut également recueillir des données
        indirectement en recherchant les coordonnées professionnelles auprès
        d’entreprises qui les ont publiées sur des sites Web (tels que Linkedin
        ou les sites Web d’entreprises qui pourraient être intéressées par les
        services de ByVets) ou dans des revues spécialisées, ou lors de congrès
        ou événements particuliers liés aux activités de ByVets).
      </p>
      <h3>Communication de données à des tiers.</h3>
      <p>
        Sauf dans des circonstances exceptionnelles (par exemple si imposées par
        la loi), en aucune manière ByVets ne divulguera ni ne vendra vos données
        à des tiers. <br /> Certaines sociétés tierces peuvent toutefois traiter
        occasionnellement vos données à caractère personnel à l’occasion de la
        fourniture de services de nature technique (sous-traitants). En aucun
        cas, vos données ne sont traitées en dehors de l’Europe.
      </p>
      <h3>Accès aux informations collectées, rectification, suppression.</h3>
      <p>
        Dans les circonstances et sous réserve des conditions prévues par la loi
        applicable, vous pouvez
        <ul>
          <li>
            demander l’accès et/ou une copie de vos données à caractère
            personnel telles qu’elles sont stockées dans les bases de données de
            ByVets.
          </li>
          <li>
            demander de corriger vos données ou d’effacer vos fichiers à
            condition que cela ne rende pas impossible l’exécution des services
            fournis par ByVets.
          </li>
          <li>
            vous opposer au traitement de vos données à caractère personnel ou
            demander la limitation de ce traitement.
          </li>
          <li>
            demander que vos données à caractère personnel soient transmises
            directement à un autre fournisseur de services.
          </li>
          <li>
            ByVets peut vous demander de fournir une preuve d’identité avant de
            faire droit à votre demande.
          </li>
        </ul>
      </p>
      <h3>Durée de conservation des données personnelles.</h3>
      <p>
        Conformément aux exigences légales imposées par la Loi et le Règlement
        RGPD, ByVets ne conserve les données personnelles des utilisateurs que
        durant le temps raisonnablement nécessaire pour permettre
        l’accomplissement des finalités pour lesquelles elles sont traitées.
      </p>
      <h3>Sécurisation des données.</h3>
      <p>
        ByVets dispose de serveurs sécurisés et seule la personne autorisée peut
        traiter les données. Toutes les données recueillies seront traitées
        confidentiellement par les employés liés par des engagements de
        confidentialité.
      </p>
      <h3>Mise à jour.</h3>
      <p>
        Cette politique de collecte et de traitement des données à caractère
        personnel peut être mise à jour. ByVets invite donc ses visiteurs ou ses
        contacts à consulter régulièrement ce lien.
      </p>
      <h3>Contacter ByVets.</h3>
      <p>
        Si vous souhaitez exercer l’un des droits énoncés ci-dessus, si vous
        avez besoin d’une assistance spécifique ou si vous souhaitez envoyer un
        commentaire, nous vous prions de bien vouloir nous contacter par l’un
        des moyens indiqués ci-dessous :
        <ul>
          <li>e-mail à : administration@byvets.be</li>
          <li>
            courrier à l’adresse suivante: rue Joseph Wauters, 67 4500 HUY
          </li>
          <li>téléphone : 085/25 08 25</li>
          <li>
            N’hésitez pas à nous contacter si vous estimez que nous ne traitons
            pas vos données avec le soin que vous attendez.
          </li>
        </ul>
      </p>
      <p>
        L’autorité de contrôle de la protection des données en Belgique peut
        être contactée à l’adresse suivante :
      </p>
      <p>
        Autorité de contrôle de la protection des données
        <ul>
          <li>Rue de la Presse, 35, 1000 Bruxelles</li>
          <li>+32 (0)2 274 48 00</li>
        </ul>
      </p>
    </div>
  );
};

export default RgpdPage;

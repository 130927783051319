import Plyr from "plyr-react";
import React from "react";
import "plyr-react/plyr.css";
import ReactPlayer from "react-player";

const MyVimeo = (props) => {
  return (
    <div className="player-wrapper">
      {!!props.src && (
        <ReactPlayer
          className="react-player"
          url={`https://vimeo.com/${props.src}`}
          controls="true"
          width="100%"
          height="100%"
        />
      )}
    </div>
  );
};

export default MyVimeo;
